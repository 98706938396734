<template>
  <b-container fluid class="overflowable" :style="{ height: this.$store.state.availableTableHeight }">
    <b-row>
      <b-col>
        <template-list></template-list>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import TemplateList from "@/components/template/TemplateList.vue";

export default {
  components: {
    "template-list": TemplateList
  }
};
</script>
